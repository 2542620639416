// extracted by mini-css-extract-plugin
export var anchor = "styles-module--anchor--eeb6c";
export var bullet = "styles-module--bullet--2fd1e";
export var bulletTitle = "styles-module--bulletTitle--83a73";
export var item = "styles-module--item--7edc8";
export var itemBullets = "styles-module--itemBullets--a8842";
export var itemDescription = "styles-module--itemDescription--fbefc";
export var itemTitle = "styles-module--itemTitle--9fad4";
export var list = "styles-module--list--9baef";
export var partnerImg = "styles-module--partnerImg--84884";
export var partners = "styles-module--partners--061ff";
export var title = "styles-module--title--f5858";
export var titleLogo = "styles-module--titleLogo--ba377";