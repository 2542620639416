// extracted by mini-css-extract-plugin
export var anchor = "styles-module--anchor--c00e2";
export var block = "styles-module--block--15528";
export var captionText = "styles-module--captionText--1bb3b";
export var columns = "styles-module--columns--89c0a";
export var content = "styles-module--content--5cb98";
export var left = "styles-module--left--df403";
export var picture = "styles-module--picture--ff3a0";
export var right = "styles-module--right--fb812";
export var title = "styles-module--title--101b0";
export var titleText = "styles-module--titleText--f4e23";