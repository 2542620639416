// extracted by mini-css-extract-plugin
export var anchor = "styles-module--anchor--0a59c";
export var bullet = "styles-module--bullet--7f9a0";
export var bulletTitle = "styles-module--bulletTitle--a757f";
export var equipment = "styles-module--equipment--c8fcc";
export var item = "styles-module--item--81b2b";
export var itemBullets = "styles-module--itemBullets--902e7";
export var itemDescription = "styles-module--itemDescription--77924";
export var itemTitle = "styles-module--itemTitle--678e9";
export var list = "styles-module--list--05420";
export var title = "styles-module--title--b5734";
export var titleLogo = "styles-module--titleLogo--bdea1";